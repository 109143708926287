<template>
    <div class="publish">
        <comment-input
            :icon="activeItem.icon"
            :title="activeItem.name"
            :desc="activeItem.desc"
            :type="activeItem.key"
        >
            <Alert
                v-if="admin && admin.userType === 'visitor'"
                slot="tip"
                type="warning"
                class="comment-tip"
                >温馨提示：访客用户只能发布“优化建议”类帖子！
            </Alert>
        </comment-input>
        <Tabs value="name1" v-model="activeIndex">
            <TabPane
                v-for="(tab, i) in typeList"
                :label="tab.name"
                :value="i"
                :key="'p_tab' + i"
            >
                <div class="empty-list" v-if="tab.count === 0">
                    <img
                        src="http://cdn.sjtuair.com/6694b050-3367-11ea-abd0-69bd09a4c42b.png"
                        alt=""
                    />
                    <div class="empty-tip">
                        还没有帖子，赶快发帖和大家讨论吧
                    </div>
                </div>
                <post-item
                    v-for="(pst, ind) in tab.list"
                    :key="'pst' + ind"
                    :post="pst"
                ></post-item>
                <Page @on-change="changePage" :total="tab.count" show-total />
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import DialogAddCompetition from "../../compontents/dialog/cooperation/DialogAddCompetition.vue";
import DialogAddResearch from "../../compontents/dialog/cooperation/DialogAddResearch.vue";
import { languageTranslation } from "../../assets/util/language";
import CommentInput from "@/compontents/home/commentInput";
import PostItem from "@/compontents/home/postItem";
export default {
    components: {
        DialogAddCompetition,
        DialogAddResearch,
        CommentInput,
        PostItem,
    },
    data() {
        return {
            info: "",
            activeIndex: 0,
        };
    },
    computed: {
        typeList() {
            return this.$store.getters.typeList || [];
        },
        activeItem() {
            return this.typeList?.length ? this.typeList[this.activeIndex] : {};
        },
    },
    mounted() {
        this.getPostList();
        this.fetchData();
    },
    methods: {
        async getPostList() {
            await Promise.all(
                this.typeList.map((type) => {
                    return this.$store.dispatch("getPostList", {
                        pageNum: type.pageNum,
                        cond: { type: type.key },
                    });
                })
            );
        },
        async submitIntention(name) {
            if (!this.admin) {
                await this.$Modal.confirm({
                    title: "登录后才能进行此操作",
                    content: "是否立即跳转到登录页面？",
                    okText: languageTranslation("确定"),
                    cancelText: languageTranslation("取消"),
                    onOk: () => {
                        this.$router.push("/common/login");
                    },
                });
                return;
            }
            this.$refs[name].edit();
        },
        async fetchData() {
            let res = await this.$http.get("/hall/explanation");
            this.info = res;
        },
        changePage(num) {
            this.$store.dispatch("getPostList", {
                pageNum: num - 1,
                cond: { type: this.activeItem.key },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.empty-list {
    img {
        width: 300px;
    }
    .empty-tip {
        margin: 30px auto;
        font-size: 18px;
    }
}
.publish {
    padding-bottom: 30px;
}
.ivu-tabs {
    margin-top: 10px;
}
.ivu-alert {
    padding: 8px 16px;
}
.comment-tip {
    margin-bottom: 0;
    margin-right: 20px;
}
.ivu-page {
    text-align: right;
}
@media screen and (max-width: 960px) {
    .comment-tip {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
    }
}
</style>
