<!--
 * @Author: Raymonda
 * @Date: 2020-06-10 14:49:48
 * @LastEditTime: 2020-06-12 19:35:34
 * @LastEditors: Raymonda
 * @Description: Description
 * @当你不能够再拥有，你唯一可以做的，就是令自己不要忘记！
-->
<template>
    <div class="post-item" v-if="post" @click.stop="goCommentDetail">
        <div v-if="post.user" class="post-user">
            <!-- <img
                class="user-face"
                :src="post.user.avatar || defaultFace"
                alt=""
            /> -->
            <div class="user-info">
                <Tag size="mini" :color="getColor(post.user.userType)">{{
                    post.user.userType | userTypeFilter
                }}</Tag>
                <span class="user-name">{{
                    post.user.nickname || post.user.phone || "暂无姓名"
                }}</span>
            </div>
        </div>
        <div class="post-main">
            <div class="post-title">{{ post.title }}</div>
            <div class="post-content">{{ post.content }}</div>
            <div class="post-info">
                <div class="post-time">{{ post.createdAt | fmt_time }}</div>
                <!-- <Icon
                    :class="[post.praised ? 'praised' : '']"
                    type="md-thumbs-up"
                    class="praise-icon"
                />
                <div class="praise-count">{{ post.praiseCount }}</div> -->
                <Icon class="comment-icon" type="md-chatboxes" />
                <div class="comment-count">{{ post.commentCount }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "post-item",
    props: {
        post: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        defaultFace() {
            return this.$store.state.defaultFace;
        }
    },
    methods: {
        getColor(val) {
            switch (val) {
                case "visitor":
                    return "orange";
                case "teacher":
                    return "primary";
                case "student":
                    return "success";
                default:
                    return "default";
            }
        },
        // 跳转详情
        goCommentDetail() {
            let _id = this.post?._id;
            if (!_id) {
                return this.$Message.error("未知帖子");
            }
            this.$router.push(`publish/detail?id=${_id}`);
        }
    }
};
</script>
<style lang="less" scoped>
.post-item {
    display: flex;
    margin: 15px 5px;
    border: 1px solid #dcdee2;
    padding: 15px;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
        box-shadow: 0px 1px 10px 0px rgba(179, 179, 179, 0.5);
    }
    .post-user {
        width: 150px;
        .user-face {
            width: 4vw;
            height: 4vw;
            border-radius: 50%;
            object-fit: cover;
        }
        .user-info {
            // margin-top: 10px;
            display: flex;
            align-items: center;
            .user-name {
                font-size: 14px;
                color: #333;
                margin-left: 5px;
                max-width: 100px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .post-main {
        margin-left: 30px;
        text-align: left;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .post-title {
            font-size: 18px;
            color: #000;
            flex-shrink: 0;
            margin-top: 6px;
        }
        .post-content {
            font-size: 16px;
            color: #555;
            flex: 1;
            margin-top: 10px;
        }
        .post-info {
            font-size: 14px;
            color: #666;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            line-height: 1;
            .praise-icon {
                font-size: 18px;
                margin-left: 15px;
                margin-top: -5px;
            }
            .praise-count {
                margin-left: 5px;
            }
            .comment-icon {
                font-size: 18px;
                margin-left: 15px;
                margin-top: -1px;
            }
            .comment-count {
                margin-left: 5px;
            }
        }
    }
    .praised {
        color: #4397ff;
    }
}
@media screen and (max-width: 960px) {
    .post-item {
        margin: 4vw 0;
        padding: 4vw;
        .post-user {
            .user-face {
                width: 40px;
                height: 40px;
            }
            .user-info {
                flex-direction: column;
                .user-name {
                    margin-top: 5px;
                }
            }
        }
        .post-main {
            margin-left: 10px;
            .post-title {
                font-size: 16px;
            }
            .post-content {
                font-size: 14px;
            }
        }
    }
}
</style>
